.blogBox {
  color: white;
  padding: 1rem;
  width: 90%;
}
.blogBox:hover {
  border: 1px grey solid;
}
.blogBox h2 {
  font-size: 2rem;
  margin-bottom: 0.1rem;
  color: white;
}
.blogBox h2:hover {
  cursor: pointer;
  color: red;
}
.tags {
  margin-bottom: 1rem;
}
.tags label {
  padding: 0 0.5rem;
}
.blogBox i {
  color: grey;
}
