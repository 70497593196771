.artContent {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 3rem;
  height: 90%;
  overflow: auto;
}
.artFooter {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.1);
  height: 5%;
}
