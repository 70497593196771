.index {
  width: 100%;
  height: 100%;
}
.tranparentBackground{
  background-color: transparent !important;
}
.deepBackground {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.header {
  background: transparent !important;
}
.footer {
  text-align: center;
  color: white !important;
  background: transparent !important;
}
.footer a {
  color: red;
}
