.content {
  height: 100%;
  display: flex;
  align-items: center;
}
.profileAvatar {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.profileContent {
  width: 60%;
  height: 70%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.profileContent h1 {
  font-family: fantasy;
  color: red;
}
.profileContent p {
  color: white;
}
