.content {
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-around;
  padding: 4rem;
  flex-wrap: wrap;
}
.imgBox {
  width: 16rem;
  height: 16rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
}
.imgBox img {
  max-width: 15rem;
  max-height: 15rem;
}
.imgBox img:hover {
  display: absolute;
  max-width: 16rem;
  max-height: 16rem;
  cursor: pointer;
}
