.menuRightSocialLink {
  display: inline-block !important;
  height: 60% !important;
  padding-top: 90% !important;
}
.menuTopSocialLink {
  float: right !important;
  margin: 0.5rem !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
